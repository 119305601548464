<template>
  <section>
    <h3 class="text-h3 mb-12 mt-8">
      Paso 2: Verificación de documentación para registro de proveedor
    </h3>

    <v-row>
      <v-col cols="12" sm="4">
        <h5 class="text-h5"><b>Obligatorios</b></h5>
        <p>Por favor ayúdanos cargando nuevamente los siguientes documentos:</p>

        <v-list dense class="list--custom">
          <v-list-item-group v-model="doc_obligatorio_selected">
            <v-list-item
              v-for="doc in docs_obligatorios"
              :key="doc.id"
              :id="doc.id"
              :class="`${setListItemStyle(doc, 1)} mb-2`"
              @click="validChangeList(1), setDataInForm(doc)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${doc.tipo_documento} *`"
                  class="text-subtitle-1"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="doc?.anteriores[0]">
                <v-icon
                  v-text="'mdi-check-circle'"
                  :class="`${setListIconItemStyle(doc, 1)} mb-2`"
                ></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider class="my-4"></v-divider>

        <h5 class="text-h5"><b>Opcionales</b></h5>
        <p>Puedes cargar también los siguientes documentos:</p>

        <v-list dense class="mb-8 list--custom">
          <v-list-item-group v-model="doc_opcional_selected">
            <v-list-item
              v-for="doc in docs_opcionales"
              :key="doc.id"
              :class="`${setListItemStyle(doc, 2)} mb-4`"
              @click="validChangeList(2), setDataInForm(doc)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${doc.tipo_documento}`"
                  class="text-subtitle-1"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="doc?.actual[0]">
                <v-icon
                  v-text="'mdi-check-circle'"
                  :class="`${setListIconItemStyle(doc, 2)} mb-2`"
                ></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="12" sm="8" v-if="isDocSelected">
        <h4 class="text-h4">{{ doc_selected?.tipo_documento }}</h4>
        <div v-if="doc_selected?.actual[0] && doc_selected?.actual[0]?.documento">
          <p class="my-6">Archivo cargado en el paso de verificación 1:</p>

          <div class="card-bordered d-flex">
            <v-icon size="36">mdi-text-box-outline</v-icon>
            <h5 class="text-h5 ml-6">
              {{
                doc_selected?.anteriores[0]?.nombre_documento
                  ? doc_selected?.anteriores[0]?.nombre_documento
                  : doc_selected?.actual[0]?.nombre_documento
              }}
            </h5>
            <v-icon size="36" class="ml-6" color="success"
              >mdi-check-circle</v-icon
            >
          </div>
        </div>

        <div v-if="(!doc_selected?.anteriores[0] && !doc_selected?.actual[0]?.opcional_en_revision) || !doc_selected?.actual[0]">
          <p class="my-6">
            Por favor carga {{ doc_selected?.actual[0] ? "nuevamente" : "" }} el
            archivo correspondiente al documento seleccionado:
          </p>

          <v-form class="card-bordered" ref="formDocs">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.numero_documento"
                  outlined
                  label="Número de documento *"
                  required
                  :placeholder="doc_selected.formato"
                  :rules="[
                    rules.required,
                    rules.validDoc(form.numero_documento, 5),
                    rules.validFormat(form.numero_documento, maskDocSelected),
                  ]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-if="doc_selected.fecha_expiracion"
                  ref="menu"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDate"
                      :label="obtenerTextoFechaDocumento(doc_selected?.fecha_label, doc_selected?.fecha_expiracion)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      class="input"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="datePicker = false"
                    v-model="form.fecha_vencimiento"
                    no-title
                    scrollable
                    :min="
                      doc_selected?.id_tipo_documento !== 4
                        ? fecha_actual
                        : null
                    "
                    :max="
                      doc_selected?.id_tipo_documento === 4
                        ? fecha_actual
                        : null
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-file-input
                  outlined
                  v-model="form.archivo"
                  accept="application/pdf"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  label="Adjuntar archivo *"
                  required
                  hint='El nombre del archivo no debe contener los siguientes carácteres: () . / # % & $ ! ¡ ¿ ? "" < >'
                  persistent-hint
                  :rules="[rules.required]"
                ></v-file-input>
              </v-col>
            </v-row>

            <div class="d-flex justify-end">
              <v-btn color="secondary" min-width="30%" @click="saveDocument"
                >Cargar</v-btn
              >
            </div>
          </v-form>
        </div>

        <div v-else>
          <div class="d-flex">
            <p class="my-6">Documento cargado con éxito</p>
            <v-icon size="36" class="ml-6" color="success"
              >mdi-check-circle</v-icon
            >
          </div>

          <div class="card-bordered d-flex">
            <v-icon size="36">mdi-text-box-outline</v-icon>
            <h5 class="text-h5 ml-6">
              {{ doc_selected?.actual[0]?.nombre_documento }}
            </h5>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex justify-end" v-if="isComplete">
      <v-btn color="secondary" solid min-width="30%" @click="confirmReview()">
        Enviar
      </v-btn>
    </div>
  </section>
</template>

<script>
  import moment from "moment-timezone";
  import { obtenerTextoFechaDocumento } from '@/utils/proveedores';

  function setDocValidation(value, doc_selected) {
    if (doc_selected.id_tipo_documento == 5) {
      const regMatriculaComercio = /^\d{1,30}$/;
      if (value) {
        return regMatriculaComercio.test(value);
      }
      return true;
    } else {
      const regDocFormat = /^[a-zA-Z0-9_-]*$/;
      if (value) {
        return regDocFormat.test(value);
      }
      return true;
    }
  }

  export default {
    name: "verificacionDocumentosView",
    data: () => ({
      docs_obligatorios: [],
      docs_opcionales: [],
      doc_obligatorio_selected: null,
      doc_opcional_selected: null,
      doc_selected: null,
      datePicker: false,
      fecha_actual: moment(Date.now()).format("YYYY-MM-DD"),
      maskDocSelected: null,
      form: {
        numero_documento: null,
        fecha_vencimiento: null,
        archivo: null,
      },
      rules: {
        required: (value) => !!value || "Este es un campo requerido",
        validDoc(value, doc_selected) {
          return (
            setDocValidation(value, doc_selected) ||
            "El campo número de documento solo admite caracteres numéricos y un máximo de 30 caracteres"
          );
        },
        validFormat(value, formato) {
          if (!formato) return true;

          const validaciones = formato.split('|');

          if (validaciones.length === 0) return true;

          const expresiones = validaciones.map((validacion) => {
            return new RegExp(`^${validacion.replace(/#/ig, '\\d')}$`);
          });

          return expresiones.some((expresion) => expresion.test(value)) || `El número de documento debe seguir el formato: ${validaciones.join(' o ')}`;
        },
      },
    }),
    computed: {
      isDocSelected() {
        return (
          this.doc_obligatorio_selected !== null ||
          this.doc_opcional_selected !== null
        );
      },
      formattedDate() {
        if (this.form.fecha_vencimiento)
          return moment(this.form.fecha_vencimiento, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          );
        return null;
      },
      isComplete() {
        const remaining = this.docs_obligatorios.filter(
          (doc) => !doc?.anteriores[0]
        );
        return remaining.length === 0;
      },
    },
    methods: {
      obtenerTextoFechaDocumento,
      async getProviderDocs() {

        const response = await this.services.Proveedores.getDocsProveedor();

        if (response?.status === 200) {
          this.docs_obligatorios = response.data.documentos_requeridos;
          this.docs_opcionales = response.data.documentos_opcionales;
        } else {
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "No se han podido cargar tus datos, vuelve a intentar más tarde por favor",
          });
        }

      },
      setListItemStyle(doc, type) {
        let clasess = "";

        if (type === 1) {
          // Obligatorios
          if (!doc?.anteriores[0]) {
            clasess +=
              this.docs_obligatorios[this.doc_obligatorio_selected]?.id ===
              doc?.id
                ? "secondary white--text"
                : "";
          } else {
            clasess += "doc-complete white--text";
          }
        } else if (type === 2) {
          // Opcionales
          if (!doc?.actual[0]) {
            clasess +=
              this.docs_opcionales[this.doc_opcional_selected]?.id === doc?.id
                ? "secondary white--text"
                : "";
          } else {
            clasess += "doc-complete white--text";
          }
        }

        return clasess;
      },
      setListIconItemStyle(doc, type) {
        let clasess = "";

        if (type === 1) {
          // Obligatorios
          if (!doc?.anteriores[0]) {
            clasess +=
              this.docs_obligatorios[this.doc_obligatorio_selected]?.id ===
              doc?.id
                ? "white--text"
                : "success--text";
          } else {
            clasess += "white--text";
          }
        } else if (type === 2) {
          // Opcionales
          if (!doc?.actual[0]) {
            clasess +=
              this.docs_opcionales[this.doc_opcional_selected]?.id === doc?.id
                ? "white--text"
                : "success--text";
          } else {
            clasess += "white--text";
          }
        }

        return clasess;
      },
      validChangeList(type) {
        if (type === 1) {
          // Obligatorios
          this.doc_opcional_selected = null;
        } else if (type === 2) {
          // Opcionales
          this.doc_obligatorio_selected = null;
        }
      },
      setDataInForm(doc) {
        this.doc_selected = doc;
        this.maskDocSelected = doc?.formato;
        setTimeout(() => {
          // Se puso el setTimeout para esperar a que la máscara se establezca
          this.form.numero_documento = doc?.actual[0]?.numero_documento;
          this.form.fecha_vencimiento =
            doc?.actual[0]?.fecha_vencimiento ?? null;
        }, 5);
      },
      async saveDocument() {
        if (this.$refs.formDocs.validate()) {


          const formData = new FormData();
          formData.append(
            "id_tipo_documento",
            this.doc_selected.id_tipo_documento
          );
          formData.append("numero_documento", this.form.numero_documento);
          formData.append("opcional_en_revision", this.doc_selected.actual[0] ? this.doc_selected.actual[0]?.opcional_en_revision : true);
          formData.append("documento", this.form.archivo);
          let response = null;

          if (!this.doc_selected?.actual[0]?.id) {
            response = await this.services.Proveedores.postProviderDocument(
              formData
            );
          } else {
            if (this.form.fecha_vencimiento)
              formData.append(
                "fecha_expiracion",
                moment(this.form.fecha_vencimiento).format("YYYY-MM-DD")
              );

            response = await this.services.Proveedores.putProviderDocument(
              this.doc_selected.actual[0].id,
              formData,
              "verificacion"
            );
          }

          if (response?.status === 200 || response?.status === 201) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Documento cargado con éxito",
            });

            this.form = {
              numero_documento: null,
              fecha_vencimiento: null,
              archivo: null,
            };
            this.doc_obligatorio_selected = null;
            this.doc_opcional_selected = null;
            this.doc_selected = null;

            await this.getProviderDocs();
          }
        }
      },
      async confirmReview() {

        const response = await this.services.Proveedores.confirmReview();
        if (response?.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message:
              "Sus datos han sido verificados, será redireccionado en 3 segundos",
          });

          setTimeout(() => {
            this.$router.replace({ name: "dashboard" });

          }, 3000);
        }
      },
    },
    async created() {
      await this.getProviderDocs();
    },
  };
</script>

<style lang="scss" scoped>
  .card-bordered {
    padding: 20px 30px;
    border-radius: 5px;
    border: 2px solid #475569;
  }

  .list--custom {
    background-color: transparent;
  }

  .list--custom .v-list-item {
    padding: 10px 15px;
    border: 1px solid var(--v-secondary-lighten1) !important;
    border-radius: 5px !important;
  }
  .list--custom .doc-complete {
    padding: 10px 15px;
    color: white !important;
    background-color: var(--v-success-darken2) !important;
    border: 1px solid var(--v-success-darken2) !important;
    border-radius: 5px !important;
  }
</style>
